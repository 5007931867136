export default defineAppConfig({
  saasName: 'Nivoria',
  customSaas: true,
  languageStrings: {
    company: 'Cliente',
    companies: 'Clientes',
    briefing: {
      visibility: 'Si el briefing se pone en "oculto" únicamente lo visualizarán los usuarios admin, y en "visible" todos los usuarios de cualquier empresa.'
    }
  },
  insights: {
    filterByCompany: false,
    filterByPlatform: false
  },
  meta: {
    adAccounts: [
      {
        name: 'Redes Lesap (116508232151666)',
        act: 116508232151666
      },
      {
        name: 'ACNUR (1035846730177492)',
        act: 1035846730177492
      }
    ]
  }
})